.connect-wallet .ant-modal-body .flex.flex-wrap {
  display: flex;
  margin: -10px;
  justify-content: center;
  flex-wrap: wrap;
}
.connect-wallet .ant-modal-body {
  padding: 125px 45px 75px 45px;
}
.connect-wallet {
  background-size: cover !important;
  background-repeat: no-repeat;
  /* background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/b58f140a0390467f9fe929a758ba551c887a997b47a69a2f9a.png); */
}
.connect-wallet .wallet-item {
  width: calc(6 / 12 * 100%);
  padding: 10px;
}
.connect-wallet {
  width: 647px !important;
  max-width: 100% !important;
  padding: 0 !important ;
}
.connect-wallet .wallet-item:nth-child(3) svg path {
  fill: #444444;
}
.connect-wallet .ant-modal-close {
  right: 30px;
  top: 22px;
  color: #000;
  border: 1px solid #2AABEE;
  box-shadow: inset 0px 0px 21px rgba(42, 171, 238, 0.58);
}
.connect-wallet .ant-modal-close-x svg {
  font-size: 25px;
  fill: rgba(0, 0, 0, 0.5);
  background: #2AABEE;
  padding: 5px;
  border-radius: 99px;
}
.connect-wallet .ant-modal-content {
  height: auto;
  background: transparent;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 2px;
}
.wallet-flex {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 3.5px;
  border-radius: 13px;
  cursor: pointer;
  transaction: 0.3s ease;
  cursor: pointer;
  position: relative;
}
.wallet-flex:hover {
  background: #f9f9fb;
}
.wallet-flex svg {
	background: #0E3A9A;
  border-radius: 2px;
	padding: 5px;
	margin-right: 17px;
}
.wallet-item .wallet-flex {
	background: #05266D;
  border: 1px solid transparent;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  transition: 0.5s ease;
}
.item-text {
  font-family: 'Kufam';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 21px;
/* identical to box height */
text-decoration: none;
display: flex;
align-items: center;
letter-spacing: 0.02em;
transition: 0.5s ease;
color: #FFFFFF;
}
.wallet-item .wallet-flex:hover .item-text {
  color: #FFFFFF;
}
.wallet-item .wallet-flex:hover {
  border: 1px solid #2AABEE;
}
.wallet-flex img {
  width: 48px;
  background: #0E3A9A;
	border-radius: 2px;
	padding: 5px;
	margin-right: 17px;
}
/* .wallet-flex:hover a {
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
} */
.connect-wallet .ant-modal-footer {
  display: none;
}
.nav-item .btnConnect {
  border: 1px solid #f27e20;
  color: #f27e20;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 5px;
}
.ant-btn.btnConnect:hover,
.ant-btn.btnConnect:focus {
  border: 1px solid #f27e20;
  color: #f27e20;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.noti-out-button .btnConnect {
  border: 1px solid #f27e20;
  color: #f27e20;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  margin: 15px auto 0;
}
.noti-out-button .ant-btn.btnConnect:hover,
.noti-out-button .ant-btn.btnConnect:focus {
  border: 1px solid #f27e20;
  color: #f27e20;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
.btn-connect {
  font-family: 'Kufam';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    padding: 0;
    color: #011135;
    width: 208.5px;
    border: 0px solid transparent;
    height: 42.5px;
    background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/68ba5bd6d4dfd83c6c810ea138bfe9a3b9df5c9c1bac4e7d7c.png);
}
.btn-connect-now {
  font-family: 'Kufam';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    padding: 0;
    color: #011135;
    width: 208.5px;
    border: 0px solid transparent;
    height: 42.5px;
    background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/68ba5bd6d4dfd83c6c810ea138bfe9a3b9df5c9c1bac4e7d7c.png);
}
.btn-connect-now a {
  color: #011135;
  text-decoration: none;
  transition: 0.3s ease;
}
.btn-connect-now a:hover {
  color: #011135;
}
.btn-connect.res-bet {
  background: #FFFFFF;
  color: #000;
}
.btn-connect img {
  width: 20px;
  margin-left: 5px;
}
.btn-account {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 33px;
  border: 1px solid #c249b7;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease;
}
.btn-account.bet {
  border: 1px solid #c249b7;
}
/* .btn-account:hover {
    background: #eeeef3;
    box-shadow: 0px 4px 20px rgba(114, 113, 113, 0.17);
    border-radius: 33px;
  } */
.account-style {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}
.btn-account .img-bnb {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.btn-account .img-bnb img {
  width: 24px;
  margin-right: 10px;
}
.btn-account .img-bnb .price {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #fff;
}
.account-style img {
  width: 20px;
  margin-right: 5px;
}
.main-dropdown-wl {
  background: #090b0d;
  border: 1px solid #303643;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
  color: #fff;
  margin-top: 9px;
}
.main-dropdown-wl .top-dropdown {
  box-shadow: inset 0px 0px 4px rgba(99, 99, 99, 0.25);
  border-radius: 6px;
}
.main-dropdown-wl .top-dropdown .box-balance {
  display: flex;
  align-items: center;
  padding: 17px 23px;
}
.main-dropdown-wl .top-dropdown .box-balance img {
  width: 60px;
}
.main-dropdown-wl .top-dropdown .box-balance .icon {
  width: 60px;
  margin-right: 20px;
  height: 100%;
}
.main-dropdown-wl .top-dropdown .box-balance .balance .name-bl {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */
  display: block;
  letter-spacing: 0.01em;

  color: #a8aeba;
}
.main-dropdown-wl .top-dropdown .box-balance .balance .number-bl {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  letter-spacing: 0.01em;
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.main-dropdown-wl .top-dropdown .box-address {
  padding: 0px 23px 11px 23px;
}
.main-dropdown-wl .top-dropdown .box-address .address-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  margin-bottom: 6px;
  border-top: 1px dashed #f78747;
  padding-top: 11px;
  color: #a8aeba;
}
.main-dropdown-wl .top-dropdown .box-address .account-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.main-dropdown-wl .top-dropdown .box-address .account-name .icon {
  margin-left: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 3px 7px;
  cursor: pointer;
  height: 100%;
  width: 30px;
}
.main-dropdown-wl .top-dropdown .box-address .account-name img {
  width: 16px;
}
.bottom-dropdown .item-rs {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 6px;
  transition: 0.3s ease;
  background: #fff;
  cursor: pointer;
}
.bottom-dropdown .item-rs:hover {
  background: #f6f7fc;
}
.bottom-dropdown .item-rs .icon-rs img {
  width: 20px;
  margin-right: 5px;
}
.bottom-dropdown {
  margin-top: 10px;
}
.bottom-dropdown .item-rs .name-rs {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  color: #687688;
  transition: 0.3s ease;
}
.bottom-dropdown .item-rs:hover .name-rs {
  color: #1e2329;
  font-weight: 600;
}
.btn-dis {
  background: #f3f3f4;
  border-radius: 99px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #1e2329;
  width: 100%;
  padding: 10px 0;
  border: 0px solid transparent;
  cursor: pointer;
  transition: 0.3s ease;
}
.btn-dis:hover {
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  box-shadow: 0px 4px 20px rgba(114, 113, 113, 0.17);
  color: #fff;
}
.footer-dropdown {
  margin-top: 10px;
  padding-top: 10px;
  margin: 0 23px;
  margin-bottom: 35px;
}
.button-profile {
  margin-top: 10px;
  padding-top: 10px;
  margin: 0 23px;
  margin-bottom: 10px;
}
.connect-wallet-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	/* identical to box height */
	color: #FFFFFF;
	text-align: center;
}
.connect-wallet .ant-modal-header {
background: transparent;
/* Note: backdrop-filter has minimal browser support */

border-radius: 2px;
	padding: 33px 0 27px 0;
}
.learning-more img {
  width: 20px;
  margin-right: 5px;
}
.learning-more {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #1e2329;
  width: 100%;
  justify-content: center;
  margin-top: 50px;
}
.button-profile .btn-profile {
  background: #1E2329;
  border-radius: 25px;
  color: #fff;
}
@media screen and (max-width: 320px) {
  .account-style {
    font-size: 9px !important;
    padding: 1px 3px !important;
  }
}
@media screen and (max-width:1440px) {
  .btn-account {
      padding: 5px;
  }
  .btn-account .img-bnb .price {
    font-size: 14px;
  }
  .account-style {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .btn-account {
    padding: 3px 7px;
  }
  .btn-account .img-bnb .price {
    font-size: 12px;
    display: none;
  }
  .account-style img {
    width: 15px;
  }
  .btn-account .img-bnb img {
    width: 15px;
  }
  .account-style {
    font-size: 12px;
    padding: 2px 5px;
  }
  .connect-wallet .wallet-item {
    width: 50%;
  }
  .connect-wallet .ant-modal-body {
    padding: 15px 5px;
  }
  .btn-account .img-bnb {
    margin-right: 0;
  }
  .btn-account .img-bnb img {
    margin-right: 0;
  }
  .tracsaction-history .icon {
    margin-left: 0;
  }
  .tracsaction-history {
    transform: translateX(50%);
    right: 50% !important;
  }
  .btn-connect {
    background: linear-gradient(93.41deg,#ffd75a -40.87%,#eb0029 170.93%);
    padding: 5px 6px;
    font-size: 12px;
    color: #fff;
    width: 130px;
    justify-content: center;
    border: 0 solid transparent;
    border-radius: 25px;
    display: flex;
    height: 40px;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 20px rgb(114 113 113 / 17%);
  }
  .btn-connect img {
      display: none;
  }
  .connect-wallet .wallet-item {
    width: 100%;
  }
  .connect-wallet {
    width: 90% !important;
    margin: 0 auto;
  }
  .connect-wallet .wallet-item {
    padding: 10px 15px;
  }
}
