.main-bet-wrapper {
  padding: 45px 0 0 0;
  position: relative;
  z-index: 2;
}
.main-bet-wrapper .props {
  position: absolute;
  top: 660px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.container-foob {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
.box-img-title {
  text-align: center;
}
.box-img-title img {
  width: 400px;
}
.main-color-bet {
  color: #FFAE00;
}
.silder-round-top {
  padding: 0 80px;
}
.silder-round-top .slick-slide {
  text-align: center;
  padding: 0 6px;
  position: relative;
  /* width: 16.6% !important; */
}
.silder-round-top .slick-list {
  padding: 45px 0;
}
.silder-round-top .slick-slide.slick-active.slick-current .item:before {
  opacity: 1;
}
.silder-round-top .slick-slide .item:before {
  content: '';
  background: #5199FF;
  width: 100%;
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.silder-round-top .slick-slide.slick-active.slick-current::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 115%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #5199FF;
}
.silder-round-top .slick-slide .item {
  background: #011A4F;
  padding: 14px 0;
  cursor: pointer;
  position: relative;
  transition: 0.3s ease;
  border: 1px solid #5199FF;
  box-shadow: inset 0px 0px 21px rgba(0, 0, 0, 0.33);
}
.silder-round-top .slick-slide .item:hover {
  background: #131313;
}
.silder-round-top .slick-track {
  margin: 0 -6px;
}
.silder-round-top .slick-slide .item .group-stage {
  font-family: 'Kufam';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 21px;
/* identical to box height */

text-align: center;

color: #88C4E2;
  margin-bottom: 5px;
}
.silder-round-top .slick-slide .item .time-stage {
  font-family: 'Kufam';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 34px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
}
.button-img-l,
.button-img-r {
  cursor: pointer;
}
.button-img-l img {
  width: 46px;
  height: 46px;
}
.button-img-r img {
  width: 46px;
  height: 46px;
}
.button-img-r {
  position: absolute;
  right: -6.2%;
  top: 50%;
  transform: translateY(-50%);
}
.button-img-l {
  position: absolute;
  left: -6.2%;
  top: 50%;
  transform: translateY(-50%);
}
.silder-round-bot .item .columns {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.silder-round-bot .item .columns .colum {
  padding: 0 10px;
}
.silder-round-bot .item .columns .colum.w-4 {
  width: 33%;
  margin-bottom: 20px;
}
.silder-round-bot .guide-bet {
  background: rgba(8, 50, 140, 0.6);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.53);
  backdrop-filter: blur(5px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 2px;
  padding: 20px;
  position: relative;
  z-index: 1;
}
.silder-round-bot .guide-bet .guide-top {
  background: #021A4F;
  border-radius: 2px;
  /* Note: backdrop-filter has minimal browser support */
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.silder-round-bot .guide-bet .guide-top .time {
  background: #010D2C;
  border-radius: 2px;
  padding: 8px 18px;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #ffffff;
}
.silder-round-bot .guide-bet .guide-top .liveTime {
  background: #F74444;
  border-radius: 2px;
  padding: 8px 18px;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  color: #ffffff;
  height: 35.19px;
}
.silder-round-bot .guide-bet .guide-top .endTime {
  background: #303A5C;
  border-radius: 2px;
  padding: 8px 18px;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  color: #C1C1C1;
  height: 35.19px;
}
.silder-round-bot .guide-bet .guide-top .price-pool {
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: #b8bbbf;
}
.silder-round-bot .guide-bet .guide-top .price-pool .cus {
  margin-left: 7px;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  display: flex;
  align-items: center;
  text-align: center;
}
.silder-round-bot .guide-bet .guide-mid {
  margin-top: 30px;
  margin-bottom: 30px;
}
.silder-round-bot .guide-bet .guide-mid .battle-score {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.silder-round-bot .guide-bet .guide-mid .battle-score .item-left {
  width: 33%;
  text-align: center;
}
.silder-round-bot .guide-bet .guide-mid .battle-score .item-mid {
  width: 33%;
  text-align: center;
}
.silder-round-bot .guide-bet .guide-mid .battle-score .item-right {
  width: 33%;
  text-align: center;
}
.silder-round-bot .guide-bet .guide-mid .battle-score .item-mid .box-img img {
  width: 55px;
}
.silder-round-bot .guide-bet .guide-mid .battle-score .box-img {
  text-align: center;
  margin-bottom: 10px;
}
.silder-round-bot .guide-bet .guide-mid .battle-score .box-img img {
  width: 84px;
  margin: 0 auto;
}
.silder-round-bot .guide-bet .guide-mid .battle-score .txt {
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
}
.silder-round-bot .guide-bet .guide-mid .battle-score .item-mid .txt {
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
}
.silder-round-bot .guide-bet .guide-bottom .bet-result {
  padding: 17px 25px;
  border-radius: 2px;
  background: #021A4F;
  border: 1px solid #2AABEE;
}
.silder-round-bot .guide-bet .guide-bottom .bet-result .ant-radio-wrapper {
  margin: 0;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
}
.silder-round-bot .guide-bet .guide-bottom .bet-result .ant-radio-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.silder-round-bot .guide-bet .guide-bottom .bet-result .ant-radio-inner {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #4a4a4a;
}
.silder-round-bot
  .guide-bet
  .guide-bottom
  .bet-result
  .ant-radio-checked:after {
  border: 1px solid #df47a8;
}
.silder-round-bot .guide-bet .guide-bottom .bet-result .ant-radio-inner:after {
  background: #FFAE00;
}
.silder-round-bot .guide-bet .guide-bottom {
  margin-bottom: 15px;
}
.silder-round-bot .guide-bet .bet-finish {
  padding: 10px 0;
  border-radius: 78px;
}
.silder-round-bot .guide-bet .bet-finish .finish-row {
  display: flex;
}
.form-control {
  background: #021A4F;
  border-radius: 2px;
  width: 100%;
  border: 0px solid transparent;
  height: 50px;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 700;
  font-size: 26px !important;
  line-height: 34px;
  text-align: center;
  padding-right: 0 !important;
  padding-left: 0 !important;
  color: #ffffff;
  padding-bottom: 15px;
}
.form-control:focus {
  background: #021A4F !important;
}
.react-numeric-input .form-control:focus {
  border-color: transparent;
  color: #fff !important;
  outline: 0;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
}
.react-numeric-input b:before {
  content: "+";
  color: #2AABEE;
  background: transparent !important;
  border-radius: 29px;
  width: 46px;
  height: 46px;
  font-size: 31px;
}
.react-numeric-input b + b:before {
  content: "-";
  color: #2AABEE;
  background: transparent !important;
  border-radius: 29px;
  width: 46px;
  height: 46px;
  font-size: 34px;
}
.react-numeric-input b {
  width: 46px !important;
  border-radius: 2px !important;
  height: 46px !important;
  background: #022E8A !important;
  box-shadow: inset 0px 0px 21px rgba(42, 171, 238, 0.58) !important;
  border-color: rgba(0, 0, 0, 0) !important;
  cursor: pointer !important;
}
.react-numeric-input b i {
  display: none;
}
.react-numeric-input b + b {
  border-radius: 2px !important;
  right: 71.5% !important;
  cursor: pointer !important;
  top: 4% !important;
  width: 46px !important;
  height: 46px !important;
  background: #022E8A !important;
  box-shadow: inset 0px 0px 21px rgba(42, 171, 238, 0.58) !important;
  border-color: rgba(0, 0, 0, 0) !important;
}
.react-numeric-input b + b i {
  display: none;
}
.finish-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.finish-row .item:nth-child(1) {
  width: 40%;
}
.finish-row .item:nth-child(2) {
  width: 20%;
}
.finish-row .item:nth-child(3) {
  width: 40%;
}
.info-bet img {
  margin: 0 auto;
  cursor: pointer;
}
.btn-bet {
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  height: 50px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  border: 0px solid transparent;
  transition: 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.btn-bet img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #062280;
  z-index: -1;
}
.btn-dis img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #062280;
  z-index: -1;
}
.btn-dis {
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  height: 50px;
  color: #5A88A0;
  cursor: pointer;
  width: 100%;
  border: 0px solid transparent;
  transition: 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.content-duel-version .box-img img {
  width: 100%;
}
.content-duel-version .box-img {
  padding-top: 50px !important;
  margin: 0 -1%;
  padding-bottom: 50px;
}
.content-slick-bet {
  padding-bottom: 80px;
  position: relative;
  z-index: 999;
}
.content-duel-version {
  padding: 0 30px;
}
.box-img-title img {
  padding: 40px 0;
  position: relative;
  z-index: 2;
}
.content-bet-logs .title {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding-bottom: 20px;
  margin-bottom: 50px;
  color: #FFFFFF;
  position: relative;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.content-bet-logs .title img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 554px;
  height: 3px;
}
.round-history-non-account {
  text-align: center;
  padding: 75px 0;
}
.round-history-non-account .wallet {
  margin-bottom: 20px;
}
.round-history-non-account .desc {
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 25px;
  color: #88C3E2;
}
.round-bet-logs .logs-l {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.round-bet-logs .logs-l.op {
  opacity: 0.2;
}
.round-bet-logs .logs-r {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.round-bet-logs .logs-r.op {
  opacity: 0.2;
}
.connect-wallet-logs .btn-connect {
  margin: 0 auto;
}
.main-table-history {
  /* Note: backdrop-filter has minimal browser support */
  margin-top: 20px;
  margin-bottom: 40px;
  border-radius: 6px;
}
.main-table-history table {
  color: #fff;
  width: 100%;
}
.main-table-history table tr th {
  text-align: left;
  padding: 13px;
  background: transparent;
}
.main-table-history table tr td {
  text-align: left;
  background: #011A4F;
  padding: 13px;
}
.main-table-history .round-score .his-r {
  display: flex;
  align-items: center;
  width: 105px;
  justify-content: space-between;
}
.main-table-history .round-score .his-r .box-img-vs img {
  width: 25px;
}
.main-table-history .round-score .his-r .box-img .img-his {
  width: 30px;
}
.main-table-history table tr td .txt {
  font-family: "Kufam";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
}
.main-table-history table tr td .txt-busd {
  font-family: "Kufam";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #FFAE00;
}
.main-table-history table tr td .statusLost {
  border-radius: 56px;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  padding: 7px;
  color: #F81414;
  width: 80px;
}
.main-table-history table tr td .statusWin {
  border-radius: 56px;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  padding: 7px;
  color: #00FF47;
  width: 80px;
}
.content-mobile-history .statusWaiting {
  border-radius: 56px;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  padding: 7px;
  color: #00B2FF;
  width: 80px;
}

.content-mobile-history .statusLost {
  border-radius: 56px;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  padding: 7px;
  color: #F81414;
  width: 80px;
}
.content-mobile-history .statusWin {
  border-radius: 56px;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  padding: 7px;
  color: #00FF47;
  width: 80px;
}
.main-table-history table tr td .statusWaiting {
  border-radius: 56px;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  padding: 7px;
  color: #00B2FF;
  width: 80px;
}
.btn-claim-logs {
  border-radius: 25px;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  padding: 10px 15px;
  width: 100px;
  color: #000000;
  border: 0px solid transparent;
  cursor: pointer;
  position: relative;
  z-index: 2;
  background: #011a4f;
  color: #fff;
}
.btn-claim-logs:disabled {
  color: #5A88A0;
  cursor: no-drop;
}
.btn-claim-logs img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.main-table-history table tr {
  position: relative;
  border: 1px solid #5199FF;
  box-shadow: inset 0px 0px 21px rgba(0, 0, 0, 0.33);
}
.main-table-history table tr:nth-child(1) {
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  box-shadow: inset 0px 0px 21px rgba(0, 0, 0, 0);
}
.ant-tooltip-inner {
  background: #021A4F !important;
  color: #fff !important;
  font-size: 12px !important;
  border-radius: 6px !important;
}
.non-data {
  padding: 100px 0;
  text-align: center;
}
.e-l {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.e-r {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.row-beted {
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  background: #021A4F;
  border: 1px solid #2AABEE;
  border-radius: 2px;
  padding: 17px 0;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.checker {
  position: absolute;
  right: -5px;
  top: -8px;
}
.row-beted .main-color-bet {
  font-weight: bold;
  margin: 0 5px;
}
.bet-sorce-two-team {
  display: flex;
  width: 150px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  /* Note: backdrop-filter has minimal browser support */
  padding: 3px;
  border-radius: 78px;
  margin-bottom: 30px;
  position: relative;
}
.bet-sorce-two-team .item-team {
  border-radius: 25px;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 26px;
  text-align: center;
  padding: 5px 0;
  color: #ffffff;
  height: 32px;
}
.bet-sorce-two-team::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 100%;
  background: #fff;
}
.comeback-result {
  text-align: center;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  margin-top: 20px;
  color: #b8bbbf;
}
@media screen and (max-width: 768px) {
  .box-logo-bet img {
    width: 150px;
  }
  .main-header {
    padding: 13px 20px;
  }
  .box-img-title img {
    width: 216px;
  }
  .silder-round-top {
    padding: 0 15px;
  }
  .silder-round-top .slick-slide {
    width: 180px !important;
  }
  .button-img-l {
    display: none;
  }
  .button-img-r {
    display: none;
  }
  .silder-round-bot .item .columns .colum.w-4 {
    width: 100%;
    margin-bottom: 20px;
  }
  .silder-round-bot {
    padding: 0 20px;
  }
  .content-slick-bet {
    padding-bottom: 20px;
  }
  .row-beted {
    font-size: 13px;
  }
  .round-bet-logs .logs-l {
    display: none;
  }
  .round-bet-logs .logs-r {
    display: none;
  }
  span.ant-radio + * {
    font-size: 12px;
  }
  .content-duel-version {
    padding: 30px 0;
  }
  .silder-round-bot .guide-bet {
    padding: 15px;
  }
  .silder-round-bot .guide-bet .bet-finish {
    padding: 5px;
  }
  .form-control {
    padding-left: 0 !important;
  }
  .finish-row .item:nth-child(1) {
    width: 45%;
  }
  .finish-row .item:nth-child(2) {
    width: 10%;
  }
  .finish-row .item:nth-child(3) {
    width: 45%;
  }
}
.row-collapse {
  display: flex;
  align-items: center;
}
.row-collapse .item {
  width: 40%;
}
.row-collapse .item .his-r {
  display: flex;
  align-items: center;
  width: 100px;
}
.row-collapse .item .his-r .box-img img {
  width: 30px;
}
.row-collapse .item .his-r .box-img-vs img {
  width: 20px;
}
.row-collapse .item .his-r .box-img-vs {
  margin: 0 5px;
}
.row-collapse .item .his-r {
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 78px;
}
.main-collapse-history .item.txt-time {
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #fff;
}
.main-collapse-history {
  padding: 30px 20px 0 20px;
}
.main-collapse-history .ant-collapse-header {
  position: relative;
}
.main-collapse-history .anticon.ant-collapse-arrow {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px;
  border-radius: 99px;
  background: #fff;
}
.main-collapse-history
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
    background: #011A4F;
    border: 1px solid #5199FF;
    box-shadow: inset 0px 0px 21px rgba(0, 0, 0, 0.33);
}
.main-collapse-history .ant-collapse {
  background: transparent;
  border: 1px solid transparent;
}
.main-collapse-history .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid transparent;
}
.main-collapse-history .ant-collapse-item {
  margin-bottom: 10px;
}
.main-collapse-history .ant-collapse-content {
  background: #011A4F;
  border-top: 1px solid #011A4F;
  border-radius: 0 0 10px 10px;
}
.content-mobile-history .item .txt {
  margin: 0 auto;
}
.content-mobile-history .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.content-mobile-history .item:last-child {
  margin-bottom: 0;
}
.content-mobile-history .item .txt-l {
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #b8bbbf;
}
.content-mobile-history .item .txt-r.res-1 {
  font-family: "Kufam";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: right;

  color: #ffffff;
}
.content-mobile-history .item .txt-r.res-2 {
  font-family: "Kufam";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;

  background: linear-gradient(95.17deg, #df47a8 -55.12%, #564eee 174.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.content-mobile-history .item .txt-r.res-3 {
  background: rgba(223, 71, 71, 0.2);
  border-radius: 56px;
  font-family: "Kufam";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  padding: 7px;
  color: #ff0909;
  width: 80px;
}

.claim-scroll {
  color: #4cd964;
}
.claim-scroll:hover {
  cursor: pointer;
}
.pagination-his {
  padding-top: 15px;
  display: flex;
  justify-content: center;
}
.pagination-his .ant-pagination-item {
  min-width: 32px;
  text-align: center;
  list-style: none;
  background-color: #030304;
  border: 1px solid #030304; /* border: 1px solid #030304; */
  /* border-radius: 2px; */
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pagination-his .ant-pagination-item-active a {
  color: #ffffff;
}
.pagination-his .ant-pagination-item-active a {
  color: #ffffff;
  border: 1px solid #030304;
  background: #262626;
  /* border: 99px; */
  border-radius: 99px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-his .ant-pagination-item a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  color: #929292;
  transition: none;
}
.pagination-his .ant-pagination-prev {
  margin-top: 10px;
}
.pagination-his .ant-pagination-next {
  margin-top: 10px;
}
.pagination-his .ant-pagination-item-link {
  background-color: #262626;
  border: 1px solid #262626;
}
.pagination-his .anticon {
  color: #fff;
}
.non-data-row {
  background: transparent !important;
}
.loadMore-his {
  padding-top: 30px;
  text-align: center;
}
.btn-loadMore-his {
  color: #fff;
  border-radius: 13px;
  height: 50px;
  width: 145px;
  border: none;
  position: relative;
  background: transparent;
  padding: 5px;
}
.btn-loadMore-his img {
  position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}
.spin-his .anticon.anticon-sync.anticon-spin {
  color: #3498db;
}
.spin-his {
  text-align: center;
  padding-top: 15px;
}
.txt-handicap {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 10px;
  color: #FFFFFF;
}
.number-ric {
  position: relative;
}
.number-ric:before {
  content: 'tickets';
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 34px;
  text-align: center;
  color: #FFFFFF;
}
.txt-ended-row {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  margin: 63px 0 40px 0;
  color: #B8BBBF;
}
.txt-ended-row.row-cus {
  margin: 20px 0 20px 0;
}
.button-bet .btn-connect {
  width: 100%;
  font-size: 14px;
  height: 50px;
}
.button-bet .btn-connect img {
  display: none;
}
.process-line .progress {
  height: 8px;
  margin-bottom: 20px !important;
  background: #000 !important;
}
.process-line .progress .progress-bar.bg-warning {
  background: #FFAE00 !important;
  border-radius: 2px 0px 0px 2px !important;
}
.process-line .progress .progress-bar.bg-success {
  background: #fff !important;
}
.process-line .progress .progress-bar.bg-info {
  background: #B51E4C !important; 
}
.process-line .title {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: #B8BBBF;
  margin-bottom: 10px;
}
.process-line .bet-round-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.process-line .bet-round-line .item {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  color: #FFFFFF;
}
.process-line .bet-round-line .item span {
  display: block;
  margin: 8px 0;
  color: #FFAE00;
}
.process-line .bet-round-line .item:nth-child(2) span {
  text-align: center;
  color: #fff;
}
.process-line .bet-round-line .item:nth-child(3) span {
  text-align: right;
  color: #B51E4C;
}
.time-countdown {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  margin-top: 20px;
  color: #B8BBBF;
}
.custom-class .ant-message-notice-content {
  display: flex;
  width: fit-content;
  padding: 5px 15px 5px 7px;
  pointer-events: all;
  margin: 0 auto;
  justify-content: right;
  box-shadow: 0px 6px 20px rgba(120, 122, 155, 0.18);
  border-radius: 36.5px;
  background: #ffffff;
}
.custom-class .ant-message-warning .anticon.anticon-exclamation-circle {
  background: linear-gradient(
    223.03deg,
    rgba(250, 198, 104, 0.2) -1.23%,
    rgba(221, 161, 52, 0.2) 102.46%
  );
  border-radius: 50%;
  padding: 10px;
}
.custom-class .ant-message-success .anticon.anticon-check-circle {
  background: linear-gradient(
    223.03deg,
    rgba(29, 230, 107, 0.2) -1.23%,
    rgba(12, 183, 79, 0.2) 102.46%
  );
  border-radius: 50%;
  padding: 10px;
}
.custom-class .ant-message-error .anticon.anticon-close-circle {
  background: linear-gradient(
    223.03deg,
    rgba(250, 94, 94, 0.2) -1.23%,
    rgba(242, 69, 69, 0.2) 102.46%
  );
  border-radius: 50%;
  padding: 10px;
}
.custom-class .ant-message-custom-content {
  display: flex;
    align-items: center;
}
.custom-class span:nth-child(2) {
  color: #1e2329 !important;
  font-weight: 600;
  font-family: 'Kufam';
  margin-top: 4px;
}
.ske-bet .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: #5199FF;
  opacity: 0.3;
}
.ske-bet .ant-skeleton-content .ant-skeleton-title {
  background: #5199FF;
  opacity: 0.3;
}
.none-claim {
  color: #3C6C85;
  padding-left: 5px;
}
@media screen and (max-width: 768px) {
  .content-bet-logs .title {
    font-size: 28px;
    margin-bottom: 25px;
  }
  .content-bet-logs .title img {
    width: 100%;
  }
  .main-bet-wrapper {
    padding: 0;
  }
}
.container-m {
  padding: 0 15px;
}
.main-header-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-header-mobile .header-l .box-logo-bet {
  margin-left: -20px;
}
.main-header-mobile .header-l .box-logo-bet img {
  width: 111px;
}
.show-menu {
  width: 40px;
  height: 40px;
  background: #000C24;
  border-radius: 3px;
  border: 0px solid transparent;
  color: #fff;
}
.drawer-menu .ant-drawer-content-wrapper {
    width: 100% !important;
}
.drawer-menu .ant-drawer-header {
    display: none;
}
.drawer-menu .ant-drawer-body {
    background: linear-gradient(180deg, #014FDB 0%, #002058 100%);
    backdrop-filter: blur(5px);
    padding: 0 15px;
}
.top-drawer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.top-drawer .left img {
    width: 111px;
}
.top-drawer .left .box-logo-bet {
    margin-left: -20px;
}
.list-menu-draw li a {
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-decoration: none;
    color: #FFFFFF;
}
.list-menu-draw li {
    list-style-type: none;
    margin-bottom: 30px;
}
.list-menu-draw {
    margin-bottom: 0;
    padding-left: 0;
    text-align: center;
    padding-top: 80px;
}
.btn-group-menu {
    text-align: center;
}
.btn-group-menu .buy-1shot {
    margin-bottom: 30px;
}
.group-social img {
    width: 40px;
}
.group-social {
    margin-top: 30px !important;
    margin: 0 auto;
    width: 150px;
    display: flex;
    justify-content: space-between;
}
.group-ball {
    margin-top: 30px;
    text-align: center;
}
.copy-right {
    font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  margin-top: 30px;
  color: #CAEDFF;
}
.res-btn-connect {
  position: relative;
}
.res-btn-connect .btn-connect {
  width: 100%;
  background: transparent;
  color: #fff;
}
.res-btn-connect img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.connect-wallet-logs .res-btn-connect {
  width: 170px;
  margin: 0 auto;
}

/* csss champion */

.tabs-worldcup .ant-tabs-nav-list {
  width: 400px;
  margin: 0 auto;
  background: #011A4F;
  border: 1px solid #5199FF;
  box-shadow: inset 0px 0px 21px rgba(0, 0, 0, 0.33);
  padding: 4px;
}
.tabs-worldcup .ant-tabs-tab {
  width: 50%;
}
.tabs-worldcup .ant-tabs-tab .ant-tabs-tab-btn {
  margin: 0 auto;
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #FFFFFF;
}
.tabs-worldcup .ant-tabs-tab.ant-tabs-tab-active {
  background: rgba(8, 50, 140, 0.6);
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 2px;
}
.tabs-worldcup .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #FFAE00;
}
.tabs-worldcup .ant-tabs-tab+.ant-tabs-tab {
  margin: 0;
}
.tabs-worldcup {
  padding-bottom: 100px;
}
.tabs-worldcup .ant-tabs-ink-bar {
  display: none;
}
.tabs-worldcup.ant-tabs-top>.ant-tabs-nav:before {
  border-bottom: 0px solid #f0f0f0
}
.content-champion {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
}
.main-color-cp {
  color: rgba(42, 171, 238, 1);
}
.champion-top {
  display: flex;
  align-items: center;
}
.champion-top .left {
  width: 50%;
}
.champion-top .right {
  width: 50%;
}
.champion-top .right .box-img {
  text-align: right;
}
.champion-top .left .title-cp {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #FFFFFF;
  margin-bottom: 10px;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.fw-bold {
  font-weight: bold;
}
.champion-top .left .desc-cp {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 137.5%;
  /* or 25px */
  margin-bottom: 10px;

  color: #FFFFFF;
}
.champion-top .left .total-prize {
  display: flex;
  align-items: center;
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;

  color: #88C3E2;
}
.champion-top .left .total-prize img {
  width: 16.67px;
  margin-left: 8px;
  margin-top: -2px;
  cursor: pointer;
}
.champion-top .left .number-prize {
  display: block;
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 47px;
  /* identical to box height */
  color: #FFAE00;
  margin-top: 10px;
}
.rules-cp {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.btn-mint {
  width: 144px;
  height:46px;
  border: 0px solid transparent;
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  padding-top: 3px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  position: relative;
  color: #FFFFFF;
  z-index: 2;
  cursor: pointer;
}
.btn-mint img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #00025d;
}
.rules-content {
  margin-left: 40px;
  cursor: pointer;
}
.rules-content span {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */
  letter-spacing: 0.02em;
  margin-left: 10px;
  color: #FFFFFF;
}
.champion-center {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  position: relative;
  padding-bottom: 50px;
  margin-bottom: 50px;
}
.champion-center .left {
  width: 50%;
}
.champion-center .right {
  width: 50%;
}
.champion-center .left .title-cp {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #FFFFFF;

  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.champion-center .list-total {
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  margin-top: 15px;
}
.champion-center .list-total li {
  list-style-type: none;
  margin-right: 45px;
  display: flex;
  align-items: center;
}
.champion-center .list-total li .txt-text {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;

  color: #88C3E2;
}
.champion-center .list-total li .txt-number {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  margin-left: 5px;
  color: #FFAE00;
}
.champion-center .right .txt {
  display: flex;
  justify-content: right;
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 2;
  /* identical to box height */
  color: #88C3E2;
}
.champion-center .right .txt span {
  padding: 10px;
  border-radius: 2px;
  background: #01143C;
  margin: 0 5px;
  box-shadow: inset 0px 0px 21px rgba(0, 0, 0, 0.33);
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  width: 46px;
  color: #FFFFFF;
}
.champion-center .img img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 540px;
  height: 10px;
}
.colspan-button {
  position: relative;
  z-index: 2;
}
.champion-bottom {
  width: 100%;
}
.main-table table {
  color: #fff;
  width: 100%;
}
.main-table table tr {
  border: 1px solid #5199FF;
}
.main-table table tr:nth-child(1) {
  border: 0px solid #5199FF;
}
.main-table table tr th {
  text-align: left;
  padding: 10px;
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  text-transform: uppercase;
  color: #CAEDFF;
  background: transparent;
}
.main-table table tr th:nth-child(1) {
  width: 5%;
}
.main-table table tr th:nth-child(2) {
  width: 15%;
}
.main-table table tr th:nth-child(3) {
  width: 25%;
}
.main-table table tr td:nth-child(3) {
  padding-right: 40px;
}
.main-table table tr th:nth-child(4) {
  width: 20%;
}
.main-table table tr th:nth-child(5) {
  width: 12.5%;
}
.main-table table tr th:nth-child(6) {
  width: 12.5%;
}
.main-table table tr th:nth-child(7) {
  width: 10%;
  text-align: right;
}
.main-table table tr td {
  text-align: left;
  background: #011A4F;
  padding: 10px;
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  color: #FFFFFF;
}
.colspan-button img {
  background: #011a4f;
}
.main-table {
  background: #021A4A;
  box-shadow: inset 0px 0px 21px rgba(0, 0, 0, 0.33);
  padding: 30px 15px;
  max-height: 442px;
  overflow-y: auto;
}
.main-table table tr td .box-score {
  display: flex;
  align-items: center;
}
.main-table table tr td .box-score .name {
  margin-left: 5px;
  margin-top: 3px;
}
.main-table table tr td .box-score img {
  width: 28px;
}
.main-table table tr td .box-img img {
  width: 40px;
}
.progess-td {
  cursor: pointer;
}
.progess-td .ant-progress-bg {
  background: #FFAE00;
  border-radius: 0;
}
.progess-td .ant-progress-inner {
  background: #000000;
  border-radius: 0;
}
.main-table table tr td .price img {
  margin-right: 5px;
}
.main-table table tr td .price {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;

  color: #FFAE00;
}
.champion-ntf {
  width: 100%;
  margin-top: 121px;
  margin-bottom: 50px;
}
.champion-ntf .title-cp {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #FFFFFF;

  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.res-btn-connect .btn-connect-now {
  background: transparent;
  position: relative;
  z-index: 2;
  width: 200px;
  height: 50px;
  color: #fff;
}
.number-id {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #FFFFFF;
}
.main-table table tr td .box-img .number-id {
  padding-left: 15px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background: #021A4A;
}

::-webkit-scrollbar-thumb {
  background-color: #fff; 
}
::-webkit-scrollbar {
  width: 8px;
  background: #021A4A;
}
.modal-rules {
    width: 620px !important;
}
.modal-rules .ant-modal-footer {
    display: none;
}
.modal-rules .ant-modal-content {
    background: rgba(8, 50, 140, 0.6);
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.53);
    backdrop-filter: blur(5px);
}
.ant-modal-title {
    text-align: center;
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-transform: uppercase;

    color: #FFFFFF;
}
.modal-rules .ant-modal-header {
    background: rgb(5 30 106 / 38%);
    border-bottom: 1px solid rgb(42 171 238 / 66%);
    padding: 25px 24px;
}
.modal-rules .ant-modal-close-x svg {
    font-size: 25px;
    fill: rgba(0, 0, 0, 0.5);
    background: #2AABEE;
    padding: 5px;
    border-radius: 99px;
}
.modal-rules .ant-modal-close {
    right: 10px;
    top: 10px;
    color: #000;
    border: 1px solid #2AABEE;
    box-shadow: inset 0px 0px 21px rgb(42 171 238 / 58%);
}
.top-content-rules {
    background: #fff;
    padding: 12px 16px;
    border-radius: 8px;
    font-family: 'Kufam';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    letter-spacing: 0.02em;

    color: #021A4F;
}
.bottom-content-rules {
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.8;
    /* or 24px */
    margin-top: 40px;

    color: #FFFFFF;
}
.ant-tooltip {
    padding: 10px;
}
.ant-tooltip-placement-bottom .ant-tooltip-inner {
    background: #fff !important;
    color: #021A4F !important;
    text-align: center;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow-content {
    --antd-arrow-background-color: #fff;
}
.main-table table tr th:nth-child(4) img {
    cursor: pointer;
}
.main-table table tr:nth-child(2) td .box-img {
    color: #011A4F;
    position: relative;
}
.main-table table tr:nth-child(2) td .box-img:before {
    content: '';
    background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/776b04dd025cb1833b2d9163e5581279c9464fd7fc6cf23e80.png);
    width: 42px;
    height: 49px;
    top: -13px;
    left: 3px;
    position: absolute;
}
.main-table table tr:nth-child(3) td .box-img {
    color: #011A4F;
    position: relative;
}
.main-table table tr:nth-child(3) td .box-img:before {
    content: '';
    background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/600dc04c4413cb5b5f44cc02622f9b860bb0ffa1de1d9df1e1.png);
    width: 42px;
    height: 49px;
    top: -13px;
    left: 3px;
    position: absolute;
}
.main-table table tr:nth-child(4) td .box-img {
    color: #011A4F;
    position: relative;
}
.main-table table tr:nth-child(4) td .box-img:before {
    content: '';
    background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/23d0dcfde773ee3eae76cf55b7b03a3bce561d734e3d8e2d2d.png);
    width: 42px;
    height: 49px;
    top: -13px;
    left: 3px;
    position: absolute;
}
.main-table table tr td .box-img {
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
}
.main-table table tr:nth-child(2) td:nth-child(1) {
    border: 1px solid #021a4a;
    border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(3) td:nth-child(1) {
    border: 1px solid #021a4a;
    border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(4) td:nth-child(1) {
    border: 1px solid #021a4a;
    border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(5) td:nth-child(1) {
    border: 1px solid #021a4a;
    border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(6) td:nth-child(1) {
    border: 1px solid #021a4a;
    border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(7) td:nth-child(1) {
    border: 1px solid #021a4a;
    border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(8) td:nth-child(1) {
    border: 1px solid #021a4a;
    border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(9) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(10) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(11) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(12) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(13) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(14) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(15) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(16) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(17) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(18) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(19) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(20) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(21) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(22) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(23) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(24) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(25) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}

.main-table table tr:nth-child(26) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(27) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(28) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(29) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(30) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(31) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(32) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table table tr:nth-child(33) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.btn-mint-dis span {
  vertical-align: 0;
}


.main-table-history-mint table tr th:nth-child(4) img {
  cursor: pointer;
}
.main-table-history-mint table tr:nth-child(2) td .box-img {
  color: #011A4F;
  position: relative;
}
.main-table-history-mint table tr:nth-child(2) td .box-img:before {
  content: '';
  background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/776b04dd025cb1833b2d9163e5581279c9464fd7fc6cf23e80.png);
  width: 42px;
  height: 49px;
  top: -13px;
  left: 3px;
  position: absolute;
}
.main-table-history-mint table tr:nth-child(3) td .box-img {
  color: #011A4F;
  position: relative;
}
.main-table-history-mint table tr:nth-child(3) td .box-img:before {
  content: '';
  background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/600dc04c4413cb5b5f44cc02622f9b860bb0ffa1de1d9df1e1.png);
  width: 42px;
  height: 49px;
  top: -13px;
  left: 3px;
  position: absolute;
}
.main-table-history-mint table tr:nth-child(4) td .box-img {
  color: #011A4F;
  position: relative;
}
.main-table-history-mint table tr:nth-child(4) td .box-img:before {
  content: '';
  background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/23d0dcfde773ee3eae76cf55b7b03a3bce561d734e3d8e2d2d.png);
  width: 42px;
  height: 49px;
  top: -13px;
  left: 3px;
  position: absolute;
}
.main-table-history-mint table tr td .box-img {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
}
.main-table-history-mint table tr:nth-child(2) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table-history-mint table tr:nth-child(3) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table-history-mint table tr:nth-child(4) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table-history-mint table tr:nth-child(5) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table-history-mint table tr:nth-child(6) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table-history-mint table tr:nth-child(7) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table-history-mint table tr:nth-child(8) td:nth-child(1) {
  border: 1px solid #021a4a;
  border-right: 1px solid #5199ff;
}
.main-table-history-mint table {
  color: #fff;
  width: 100%;
}
.main-table-history-mint table tr {
  border: 1px solid #5199FF;
}
.main-table-history-mint table tr:nth-child(1) {
  border: 0px solid #5199FF;
}
.main-table-history-mint table tr th {
  text-align: left;
  padding: 10px;
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  text-transform: uppercase;
  color: #CAEDFF;
  background: transparent;
}
.main-table-history-mint table tr td {
  text-align: left;
  background: #011A4F;
  padding: 10px;
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  color: #FFFFFF;
}
.colspan-button img {
  background: #011a4f;
}
.main-table-history-mint {
  background: #021A4A;
  box-shadow: inset 0px 0px 21px rgba(0, 0, 0, 0.33);
  padding: 30px 15px;
  max-height: 442px;
  overflow-y: auto;
}
.main-table-history-mint table tr td .box-score {
  display: flex;
  align-items: center;
}
.main-table-history-mint table tr td .box-score .name {
  margin-left: 5px;
  margin-top: 3px;
}
.main-table-history-mint table tr td .box-score img {
  width: 28px;
}
.main-table-history-mint table tr td .box-img img {
  width: 40px;
}
.main-table-history-mint table tr td .box-img-nft img {
  width: 60px;
  height: 60px;
  border-radius: 2px;
}
.main-table-history-mint table tr th:nth-child(1) {
  width: 5%;
}
.main-table-history-mint table tr th:nth-child(2) {
  width: 20%;
}
.main-table-history-mint table tr th:nth-child(3) {
  width: 20%;
}
.main-table-history-mint table tr th:nth-child(4) {
  width: 35%;
}
.main-table-history-mint table tr th:nth-child(5) {
  width: 20%;
}
.main-table-history-mint table tr.non-data-mint td {
  border: 1px solid #5199ff !important;
}
.non-data-mint-his {
  padding: 70px 0;
  text-align: center;
}
.main-table-history-mint table tr .stt {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
}
@media screen and (max-width :768px) {
  .tabs-worldcup {
    padding: 0 15px 0 15px;
  }
  .champion-top {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    width: 100%;
  }
  .champion-top .left {
    width: 100%;
  }
  .champion-top .right {
    width: 100%;
  }
  .champion-top .right .box-img img {
    width: 100%;
  }
  .champion-top .right .box-img {
    margin-bottom: 30px;
  }
  .champion-top .left .title-cp {
    font-size: 30px;
    line-height: 1.2;
  }
  .champion-top .left .desc-cp {
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 1.6;
  }
  .champion-top .left .number-prize {
    font-size: 30px;
  }
  .rules-cp {
    margin-top: 10px;
  }
  .champion-center .left {
    width: 100%;
  }
  .champion-center .right {
    width: 100%;
  }
  .champion-center .right .txt {
    display: block;
    text-align: center;
    margin-top: 5px;
  }
  .champion-center .right .txt .time {
    margin-top: 15px;
  }
  .champion-ntf {
    margin-top: 50px;
  }
  .champion-ntf .title-cp {
    font-size: 30px;
  }
  .champion-ntf .title-cp .img img {
    width: 100%;
  }
}
.header-top-mint {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.header-top-mint .row-item.price {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;

  color: #FFAE00;
}

.header-top-mint .row-item:nth-child(1) {
  width: 10%;
}
.header-top-mint .row-item:nth-child(2) {
  width: 20%;
}
.header-top-mint .row-item:nth-child(3) {
  width: 25%;
}
.header-top-mint .row-item:nth-child(4) {
  width: 35%;
}
.collapse-mint .ant-collapse-expand-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  border-radius: 99px;
  text-align: center;
}
.collapse-mint.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  margin-right: 0;
  padding: 5px 6px;
}
.collapse-mint.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
  font-size: 12px;
  transform: rotate(-90deg) !important;
}
.collapse-mint.ant-collapse>.ant-collapse-item.ant-collapse-item-active>.ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(90deg) !important;
}
.collapse-mint.ant-collapse>.ant-collapse-item {
  border: 1px solid rgba(81, 153, 255, 1); 
  background: #021A4A;
  margin-bottom: 15px;
}
.collapse-mint.ant-collapse {
  border: 0px solid rgba(81, 153, 255, 1);
  background: transparent;
}
.header-top-mint .row-item .logo img {
  width: 40px;
}
.header-top-mint .row-item .name {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #FFFFFF;
}
.header-top-mint .row-item .stt {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #FFFFFF;
}
.header-top-mint .row-item .ant-progress-bg {
  background: #FFAE00;
  border-radius: 0;
}
.header-top-mint .row-item .ant-progress-inner {
  background: #000000;
  border-radius: 0;
}
.colum-mint .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.collapse-mint .ant-collapse-content>.ant-collapse-content-box {
  background: #021A4A;
}
.collapse-mint .ant-collapse-content {
  border-top: 1px solid rgba(81, 153, 255, 1);
}
.colum-mint .item .txt-l {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-transform: uppercase;

  color: #CAEDFF;

}
.colum-mint .item .txt-r {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;

  color: #FFAE00;
}
.colum-mint .item.center {
  justify-content: center;
  margin-top: 15px;
}
.main-table-history-mint table tr td .balance {
  font-family: 'Kufam';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;

  color: #FFAE00;
}