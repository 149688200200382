.container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
}
.header-foob {
    background: linear-gradient(180deg, #020C68 0%, rgba(2, 12, 104, 0) 100%);
}
.main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.main-header .header-l {
    width: 40%;
}
.main-header .header-center {
    width: 20%;
}
.main-header .header-r {
    width: 40%;
}
.main-header .header-r .gr-button {
    display: flex;
    justify-content: end;
}
.main-header .header-r .ant-dropdown-trigger {
    margin-left: auto;
}
.main-header .header-l .list-menu {
    display: flex;
    margin-left: -20px;
}
.main-header .header-l .list-menu li {
    list-style-type: none;
    padding: 10px 20px;
}
.main-header .header-l .list-menu li.active {
    color: #FFAE00;
}
.buy-1shot {
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */
    background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/0d3172e22ba1f4ddcbbeda7b8463152ecca2515a353f47a4f2.png);
    border: 0px solid transparent;
    color: #011135;
    width: 193px;
    height: 42.5px;
    text-align: center;
}
.buy-1shot a {
    color: #011135;
    text-decoration: none;
}
.buy-1shot a:hover {
    color: #011135;
}
.main-header .header-l .list-menu li a {
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height */
    text-decoration: none;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: #FFFFFF;
}
.header-center .box-logo-bet {
    text-align: center;
}
.header-center .box-logo-bet img {
    width: 111px;
    margin: 0 auto;
}
.main-footer {
    max-width: 1170px;
    margin: 0 auto;
    width: 100%;
    color: #fff;
    padding: 48px 0;
    border-bottom: 0.5px solid #7e829d4f;
}
.footer {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
}
.w-3 {
    width: 25%;
}
.main-footer .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 45px;
    color: #FFFFFF;
}
.main-footer .item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    transition: 0.3s esae;
    margin-bottom: 15px;
    color: #A8AEBA;
    cursor: pointer;
    display: flex;
}
.main-footer .item:last-child {
    margin-bottom: 0;
}
.main-footer .item.res:hover {
    background: linear-gradient(93.41deg, #FFD75A -40.87%, #EB0029 170.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.main-footer .colum:last-child .title {
    margin-bottom: 5px;
}
.price-ft {
    display: flex;
    align-items: center;
    margin-top: 36px;
}
.price-ft .txt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */
    
    margin-left: 5px;
    color: #A8AEBA; 
}
.buy-ft {
    display: flex;
    align-items: center;
    margin-top: 36px;
    margin-left: 20px;
}
.buy-ft .txt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */
    
    margin-left: 5px;
    color: #A8AEBA; 
}
.buy-ft .txt a{
    color: #fff;
}
.copy-right {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    padding: 20px 0;
    text-align: center;
    color: #7E829D;
}
.list-social {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
}
.list-social li {
    width: 33%;
    list-style-type: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    transition: 0.3s esae;
    margin-bottom: 15px;
    color: #A8AEBA;
    cursor: pointer;
}
.list-social li a {
    color: #A8AEBA;
}
.main-footer .item .cus {
    color: #fff !important;
}
.list-social li:hover {
    background: linear-gradient(93.41deg, #FFD75A -40.87%, #EB0029 170.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.list-social li img {
    margin-right: 10px;
}
.grap-2-colum .colum.w-3:nth-child(1) {
    width: 20%;
}
.grap-2-colum .colum.w-3:nth-child(2) {
    width: 30%;
}
.grap-2-colum .colum.w-3:nth-child(3) {
    width: 25%;
    padding-left: 50px;
}
.grap-2-colum .colum.w-3:nth-child(4) {
    width: 25%;
}
.btn-buy {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    font-family: 'Poppins';
    text-align: center;
    letter-spacing: 0.01em;
    background: #20DFA8;
    border-radius: 6px;
    color: #FFFFFF;
    padding: 12px;
    border: 1px solid transparent;
}
.box-logo img {
    width: 109px;
}
.main-footer .item a {
    color:#A8AEBA;
}
.box-logo-bet img {
    width: 236px;
}
.footer-bet .copy-bet {
    padding: 28px 0;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #7E829D;
}
@media screen and (max-width: 768px) {
    .grap-2-colum .colum.w-3:nth-child(1) {
        width: 50%;
        order: 2;
        margin-top: 60px;
    }
    .grap-2-colum .colum.w-3:nth-child(2) {
        width: 100%;
        order: 4;
        margin-top: 60px;
    }
    .grap-2-colum .colum.w-3:nth-child(3) {
        width: 50%;
        order: 3;
        margin-top: 60px;
        padding-left: 0;
    }
    .grap-2-colum .colum.w-3:nth-child(4) {
        width: 100%;
        order: 1;
    }
    .main-footer {
        padding: 30px 15px;
    }
    .main-footer .title {
        margin-bottom: 25px;
    }
}
.wallet-1s {
    margin-left: 10px;
    background: #fff;
    padding: 5px;
    border-radius: 99px;
    width: 43px;
    height: 43px;
}
/* .wallet-1s img {
    height: 42.5px;
} */